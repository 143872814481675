<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <loading :active.sync="isLoading" :is-full-page="false" />

      <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
        <div class="flex items-center">
          <label
            class="circle"
            :class="getSelectedClass('SCOOTER')"
            v-tooltip.top="'Scooter'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="SCOOTER"
              v-model="form.vehicle_type"
            />
            <img
              src="@/assets/icon/scooter.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>

          <label
            class="circle"
            :class="getSelectedClass('SCOOTER PRO')"
            v-tooltip.top="'Scooter Pro'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="SCOOTER PRO"
              v-model="form.vehicle_type"
            />
            <img
              src="@/assets/icon/scooter.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>

          <label
            class="circle"
            :class="getSelectedClass('BIKE')"
            v-tooltip.top="'Bike'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="BIKE"
              v-model="form.vehicle_type"
            />
            <i class="fa fa-bicycle" style="font-size: 28px" />
          </label>

          <label
            class="circle"
            :class="getSelectedClass('EBIKE')"
            v-tooltip.top="'EBike'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="EBIKE"
              v-model="form.vehicle_type"
            />
            <i class="fa fa-motorcycle" style="font-size: 28px" />
          </label>
          <label
            class="circle"
            :class="getSelectedClass('COCO')"
            v-tooltip.top="'SuperCoco'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="COCO"
              v-model="form.vehicle_type"
            />
            <img
              src="@/assets/icon/supercoco.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>
          <label
            class="circle"
            :class="getSelectedClass('KENOTA')"
            v-tooltip.top="'Kenota'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="KENOTA"
              v-model="form.vehicle_type"
            />
            <img
              src="@/assets/icon/kenota.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>
          <label
            class="circle"
            :class="getSelectedClass('MOPED')"
            v-tooltip.top="'Moped'"
          >
            <input
              type="checkbox"
              class="hidden"
              value="MOPED"
              v-model="form.vehicle_type"
            />
            <img
              src="@/assets/icon/kenota.png"
              style="height: 26px; padding-bottom: 2px"
            />
          </label>
        </div>

        <AppInput
          v-model="form.subscription"
          type="richselect"
          name="subscription"
          :label="
            $t(
              'components.organizationManagement.addEdit.steps.subscription.title.subscriptionPlan'
            )
          "
          rules="required"
          :placeholder="
            $t(
              'components.organizationManagement.addEdit.steps.subscription.placeHolder.selectAplan'
            )
          "
          value-attribute="id"
          text-attribute="plan_name"
          :infoDescription="
            $t(
              'components.organizationManagement.addEdit.steps.subscription.infoDescription.subscriptionPlan'
            )
          "
          :options="subscriptionPlans"
        />

        <button type="submit" ref="submitButton" class="hidden">
          {{ $t('components.stepNavigation.save') }}
        </button>
      </form>
    </ValidationObserver>

    <div class="px-6" v-if="subscription">
      <div class="mb-3 text-sm font-bold text-gray-800 disable-border">
        {{
          $t(
            'components.organizationManagement.addEdit.steps.subscription.subTitle.featuresOf'
          )
        }}
        {{ subscription.plan_name }}
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.currency'
            )
          }}
        </div>
        <div class="right">
          {{
            subscription.multi_currency_enabled
              ? this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.multiple'
                )
              : this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.single'
                )
          }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.language'
            )
          }}
        </div>
        <div class="right">
          {{
            subscription.multi_language_enabled
              ? this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.multiple'
                )
              : this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.single'
                )
          }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.geofenceType'
            )
          }}
        </div>
        <div class="right">
          {{
            subscription.multi_geofence_enabled
              ? this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.multiple'
                )
              : this.$t(
                  'components.organizationManagement.addEdit.steps.subscription.value.single'
                )
          }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.numberOfVehicles'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.num_of_vehicles }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.numberOfFleets'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.num_of_fleets }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.numberOfPromoCodes'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.num_of_promo_codes }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.numberOfOrgUserAccounts'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.num_of_org_user_accounts }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.endRidePhoto'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.end_ride_photo_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.endRidePhoto'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.end_ride_photo_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.accessToOperatorApp'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.operator_app_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.pointManagement'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.point_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.passManagement'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.pass_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.ticketManagement'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.ticket_management_enabled ? 'Yes' : 'No' }}
        </div>
      </div>

      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.reporting'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.reporting_enabled ? 'Yes' : 'No' }}
        </div>
      </div>
      <div class="feature-item">
        <div class="left">
          {{
            $t(
              'components.organizationManagement.addEdit.steps.subscription.subTitle.seviceAreaSwapping'
            )
          }}
        </div>
        <div class="right">
          {{ subscription.service_area_swapping_enabled ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import { useEndpoints } from '@/composables'
import { getFormModel } from './index'

export default {
  name: 'Step2',

  components: {},

  props: {
    primaryKey: {
      required: true,
    },
    subscriptionPlans: {
      type: Array,
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: getFormModel({ step: 2 }),

      // selected subscription plan details
      subscription: null,
    }
  },

  computed: {
    // this is for watching
    subscriptionId() {
      return this.form.subscription
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          // data.vehicle_type = JSON.parse(data.vehicle_type)
          console.log(data, 'dWatched')
          this.form = { ...data }
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 2, data: this.form })
      },
    },

    subscriptionId: {
      deep: false,
      immediate: true,
      handler(updatedSubscriptionId) {
        this.subscription = this.subscriptionPlans.find((subscription) => {
          return subscription.id === updatedSubscriptionId ? subscription : null
        })
      },
    },
  },

  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      const url = useEndpoints.organization.update(this.primaryKey)

      const method = 'PATCH'

      const formDataProxy = { ...this.form }
      // vehicle type needs to be a json string
      formDataProxy.vehicle_type = JSON.stringify(this.form.vehicle_type)

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 2, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err.response)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: 'Status: ' + err.response.status,
          })
        })
        .finally(() => (this.isLoading = false))
    },

    getSelectedClass(val) {
      return {
        selected: this.form.vehicle_type.includes(val),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$yellow: #fff310;
$gray: #9c9c9c;
$light-gray: #e2e8f0;

.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  padding: 0; // 15px 0?

  & .left {
    width: 55%;
    font-size: 0.875rem;
    padding: 0.375rem 0;
    // padding-right: 0.5rem;
    font-weight: 500;
  }
  & .right {
    width: 45%;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.375rem 0;
    // padding-left: 0.5rem;
    color: $gray;
  }
}

.circle {
  width: 55px;
  height: 55px;
  margin: 17px 11px;
  display: flex;
  justify-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 13px 15px;
  border: 1px solid $gray;
  // background-color: $gray;

  &:hover {
    background-color: lighten($yellow, 18%);
  }

  &.selected {
    background-color: $yellow;
  }
}
</style>
